<template>
  <router-view />
</template>

<script>
  export default {
    name: 'AppStack',
  };
</script>

<style scoped>

</style>
